/* CampaignDetail.css */

.campaign-detail {
  padding: 0px;
}

.progress {
  height: 30px;
  margin-bottom: 20px;
}

.saveLeadListCampaignButton {
  margin-top: 10px;
}

.progress-bar-striped {
  background-color: #405cdc !important;
}

.workflow-container {
  width: 100%;
  height: 500px; /* Adjust as needed */
}





/* Scheduler css */

.schedule-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
  background: #f9f9f9;
  padding: 12px;
  border-radius: 8px;
  max-width: 450px;
}

.schedule-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 0;
  border-bottom: 1px solid #ddd;
}

.schedule-row.disabled {
  opacity: 0.5;
}

.day-label {
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 500;
  min-width: 130px;
}

.clock-icon {
  color: #777;
}

.schedule-inputs {
  display: flex;
  align-items: center;
  gap: 6px;
}

.time-separator {
  font-weight: bold;
  color: #555;
}

.time-input {
  position: relative;
}

.form-control {

  padding: 4px;
  padding-right: 24px;
  cursor: pointer;
}

.dropdown-icon {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  color: #777;
  pointer-events: none;
}

/* Toggle Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-round {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 10px;
}

.slider-round:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider-round {
  background-color: #4CAF50;
}

input:checked + .slider-round:before {
  transform: translateX(14px);
}











/* Improve save button styling */
.btn-primary {
  position: relative;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
}

.btn-primary:hover {
  background-color: #324bb2;
  transform: scale(1.05);
}

/* Add styles for button while saving */
.btn-primary.saving {
  background-color: #2a3e90;
  cursor: not-allowed;
  opacity: 0.7;
}

/* Add spacing for spinner inside button */
.btn-primary .spinner-border {
  margin-right: 8px;
}
