/* SubscriptionComponent.css */

.dashboard {
    display: flex;
  }
  
  .container {
    flex: 1;
    padding: 0px;
  }
  
  .content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .subscription-details {
    margin-bottom: 20px;
  }
  
  .subscription-details h3 {
    margin-bottom: 10px;
  }
  
  .cancel-button {
    background: #f44336;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .checkout-form {
    margin-bottom: 20px;
  }
  
  .plan-selection {
    margin-bottom: 20px;
  }
  
  .plan-selection label {
    display: block;
    margin-bottom: 10px;
  }
  
  .button {
    background: #4caf50;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .button[disabled] {
    background: #ccc;
    cursor: not-allowed;
  }
  
  .success-message {
    background: #4caf50;
    color: #fff;
    padding: 10px;
    border-radius: 4px;
    margin-top: 20px;
  }
  






/* Tab styling */
.tabs {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  border-bottom: 1px solid #dcdfe6;

  overflow-x: auto; /* Enable horizontal scroll if needed */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling for mobile */
  margin-bottom: 30px;
}

.tab {
  padding: 10px 0px;
  max-width: 160px !important;
  min-width: 160px !important;
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-weight: bold;
  color: #606266;
  border-bottom: 2px solid transparent;
  margin-right: 10px;
  transition: color 0.3s, border-bottom 0.3s;
  flex: 1 1 auto; /* Adjust width dynamically */
  text-align: center; /* Center text within the tab */
}

.tab:hover {
  color: #405cdc;
}

.tab.active {
  color: #405cdc;
  border-bottom: 2px solid #405cdc;
}

.tabs-hr {
  margin: 0;
  border: none;
  border-top: 1px solid #dcdfe6;
}

/* Additional styling for better mobile experience */
@media (max-width: 768px) {
  .tab {
    padding: 8px 10px; /* Reduce padding on smaller screens */
  }
}

  



.alert {
  margin-top: 20px;
}



.widget-settings {
  margin-bottom: 10px;
}



.AddPaymentMethod {
  max-width: 250px;
}



.pageSectionDivider {
  margin-top: 30px;
  margin-bottom: 30px;
}



.paymentMethodCard {
  margin-top: 5px;
  margin-bottom: 5px;
}