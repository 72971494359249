/* Home.css */



.stats-card-span-value {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: -15px;
}

.stats-percentage-value {
  margin-right: 5px;
}

.card-label {
  margin-top: 15px;
}


.percentage-box {
  margin-top: 10px;
}


th {
  width: 150px;
}

.thIcon {
  max-width: 30px !important;
}

/* Stats Cards */
.stats-card {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 0px;
  text-align: left;
  margin-top: 20px;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.stats-icon {
  font-size: 1.5rem;
  color: #405cdc;
}

.stats-span {
  color: rgb(100 116 139);
}

.font-medium {
  font-weight: 500;
}
.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.stats-card-value {
  margin-bottom: 5px;
  margin-top: 1rem;
}

.shadow-default {
  --tw-shadow: 0px 8px 13px -3px rgba(0, 0, 0, .07);
  --tw-shadow-colored: 0px 8px 13px -3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.border-stroke {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
}
.border {
  border-width: 1px;
}
.rounded-sm {
  border-radius: 0.125rem;
}

.stats-span-percentage {
  float: right;
  margin-top: 10px;
}

/* Home.css */

.percentage-positive {
  background-color: #ecfdf3; /* Light green background */
  color: #039855; /* Keep text color green */
  display: inline-flex;
  align-items: center;
  padding-left: 10px; /* Ensure space for the "+" */
  border-radius: calc(infinity * 1px);
}

.percentage-positive::before {
  content: '+';
  margin-right: 2px; /* Space between symbol and number */
}

.percentage-negative {
  background-color: #fef3f2; /* Light red background */
  color: #d92d20; /* Keep text color red */
  display: inline-flex;
  align-items: center;
  padding-left: 10px; /* Ensure space for the "-" */
  border-radius: calc(infinity * 1px);
}

.percentage-negative::before {
  content: '-';
  margin-right: 2px; /* Space between symbol and number */
}





.bg-meta-2 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 242 247 / var(--tw-bg-opacity));
}
.rounded-full {
  border-radius: 10px;
}
.justify-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.w-11\.5 {
  width: 2.875rem;
}
.h-11\.5 {
  height: 2.875rem;
}
.flex {
  display: flex;
}


/* Latest Activities Widget */
.activity-widget {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
}

.activity-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.activity-list li {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.activity-icon {
  font-size: 1.5rem;
  margin-right: 10px;
}

.activity-highlight {
  font-weight: bold;
  color: #405cdc; /* Accent color */
}

.user-icon {
  color: #405cdc; /* User-related activity color */
}

.envelope-icon {
  color: #28a745; /* Message-related activity color */
}

/* Campaign Progress Widget */
.campaign-progress-widget {
  background-color: #f8f9fa;
  border-radius: 0.125rem;
  padding: 20px;
  margin-bottom: 20px;
}

.campaign-progress-table {
  width: 100%;
  border-collapse: collapse;
}

.campaign-progress-table th,
.campaign-progress-table td {
  text-align: left;
  padding: 8px;
}

.campaign-progress-table th {
  background-color: #405cdc; /* Header background color */
  color: white;
}

.campaign-progress-table tr:nth-child(even) {
  background-color: #f8f9fa;
}

.campaign-progress-table tr:nth-child(odd) {
  background-color: #e9ecef;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .stats-card {
    margin-bottom: 10px;
  }

  .activity-list li {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }
}


/* Activities Widget */
.activities-widget {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.activities-widget-home h2 {
  font-size: 18px;
  margin-bottom: 15px;
}

.activity-icon {
  font-size: 20px;
  margin-right: 10px;
  color: #405cdc; /* Accent color */
}

.table {
  font-size: 14px;
}

.table th,
.table td {
  vertical-align: middle;
  text-align: left;
}

.table th:first-child,
.table td:first-child {
  width: 30px;
}

/* Rest of your existing CSS for the dashboard */


/* Ensure the activities-widget takes full width of the column */
.activities-widget {
  display: flex;
  flex-direction: column; /* Stack header and table vertically */
}

/* Container for header and button */
.header-container {
  display: flex;
  justify-content: space-between; /* Pushes the button to the right */
  align-items: center; /* Aligns items vertically */
  margin-bottom: 1rem; /* Space between header and table */
  min-height: 45px;
}

.header-container-home h2 {
  margin: 0; /* Remove default margin to align better */
}

.header-container button {
  margin-left: 1rem; /* Space between header and button */
}

/* Add this CSS to your existing Home.css */

/* Campaigns Progress Widget */
.campaigns-progress-widget {
  background-color: #ffffff;
  border-radius: 0.125rem;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.campaigns-progress-widget h2 {
  font-size: 18px;
  margin-bottom: 15px;
}

.campaign-icon {
  font-size: 20px;
  margin-right: 10px;
  color: #405cdc; /* Accent color */
}

.table {
  font-size: 14px;
}

.table th,
.table td {
  vertical-align: middle;
  text-align: left;
}

.table th:first-child,
.table td:first-child {
  width: 30px;
}




.activities-widget-home {
  min-height: 470px;
}






/* Status labels */

/* Common styling for status labels */
.status-label-container {
  display: inline-block;
  padding: 2px 5px;
  border-radius: 5px;
  font-weight: bold;
  color: white; /* Default text color */
  margin: 0 5px;
  text-align: center;
  min-width: 80px; /* Ensures a minimum width for labels */
}

/* Styles for each status */
.status-label-concept {
  background-color: #f1f5f9; /* Grey */
  color: #000; /* Darker text color for readability */
}

.status-label-active {
  background-color: #28a745; /* Green */

}

.status-label-paused {
  background-color: #ffc107; /* Yellow */
  color: #333; /* Darker text color for readability */
  
}

.status-label-completed {
  background-color: #405cdc; /* Blue */
}

.status-label-stopped {
  background-color: #dc3545; /* Red */
}

.status-label-archived {
  background-color: #6f42c1; /* Purple */
  opacity: .5;
}




.row-1 {
  min-height: 500px;
  max-height: 500px;
  margin-top: 20px;
}


.campaigns-home {
  margin-bottom: 40px;
}

.activities-widget-home {
  max-height: 400px; /* Set a maximum height for the widget */
  /* Enable vertical scrolling */
}



/* Timeline */
.timeline {
  position: relative;
  margin-top: -10px;
  overflow-y: auto;  /* Enable vertical scrolling */
  padding-left: 15px;
  max-height: 400px; /* Set a max height for the scrollable area */
  scrollbar-width: thin; /* Thin scrollbar for Firefox */
  scrollbar-color: #405cdc #f1f5f9; /* Custom scrollbar color for Firefox */
}

.timeline::-webkit-scrollbar {
  width: 8px;               /* Width of the scrollbar */
}

.timeline::-webkit-scrollbar-thumb {
  background-color: #405cdc; /* Color of the scrollbar thumb */
  border-radius: 4px;        /* Rounded corners */
}

.timeline::-webkit-scrollbar-track {
  background-color: #f1f5f9; /* Background of the scrollbar track */
}

/* This container will hold the line and expand with the timeline content */
.timeline-content-wrapper {
  position: relative;
}

.timeline-content-wrapper::before {
  content: "";                   /* Create the vertical line */
  position: absolute;            /* Position it absolutely */
  left: 0;                       /* Align to the left */
  top: 0;                        /* Start the line at the top */
  bottom: 0;                     /* Extend the line to the bottom of the container */
  width: 2px;                    /* Width of the line */
  background-color: #D9E2EC;     /* Color of the line */
  margin-left: 15px;
  z-index: 1;                    /* Ensure it stays behind the events */
}

.timeline-event {
  position: relative;
  margin: 20px 0;
  padding-left: 40px;
  z-index: 2;                    /* Ensure it stays above the line */
}

.timeline-icon {
  position: absolute;
  left: 0px;
  top: 20px;
  background-color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #405cdc;
  z-index: 2;                    /* Ensure the icon appears above the line */
}

.timeline-content {
  background-color: #F9FAFB;
  padding: 10px 15px;
  border-radius: 6px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
  transition: background-color 0.2s ease-in-out;
}

.timeline-content:hover {
  background-color: #E3E6EA;
}

.timeline-header {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  margin-bottom: 5px;
}

.timeline-activity {
  color: #1F2937;
}

.timeline-date {
  font-size: 0.85em;
  color: #9CA3AF;
  margin-top: 5px;
}










.rate-bar {
  position: relative;         /* Positioning for the fill */
  background-color: #f1f5f9; /* Background color for the rate bar */
  border-radius: 8px;        /* Rounded corners */
  height: 10px;              /* Height of the rate bar */
  overflow: hidden;          /* Clip the fill to stay within the bar */
  margin-bottom: 5px;        /* Space between the bar and percentage text */
}

.rate-fill {
  height: 100%;              /* Fill takes full height */
  background-color: #007bff; /* Color of the fill */
  border-radius: 8px;        /* Match corners with the rate bar */
  transition: width 0.3s ease; /* Smooth transition for width change */
}






.hide {
  display: none;
}